.label {
  @apply flex cursor-pointer text-sm font-light;
}

.label > input[type='checkbox'] {
  @apply hidden;
}

.label > input[type='checkbox'] + span {
  @apply relative border border-border rounded-[1px] mr-2 flex-shrink-0 mt-1;
  width: 14px;
  height: 14px;
}

.label > input[type='checkbox']:checked + span::after {
  content: '';
  width: 8px;
  height: 8px;

  @apply absolute left-[2px] right-[2px] top-[2px] bottom-[2px] bg-primary;
}
