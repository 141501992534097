.dropdown {
  @apply absolute left-0 right-0 bg-white rounded overflow-hidden;
  box-shadow: 0 0px 4px 0 rgb(0 0 0 / 0.25);
  top: calc(100% + 10px);
  max-height: 200px;
}

.select {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.94875 4.69875L6.19875 8.44875C6.14602 8.50142 6.07453 8.53101 6 8.53101C5.92547 8.53101 5.85399 8.50142 5.80125 8.44875L2.05125 4.69875C2.00157 4.64544 1.97453 4.57492 1.97581 4.50206C1.9771 4.42919 2.00662 4.35968 2.05815 4.30815C2.10968 4.25662 2.17919 4.2271 2.25206 4.22581C2.32492 4.22453 2.39544 4.25157 2.44875 4.30125L6 7.85203L9.55125 4.30125C9.60457 4.25157 9.67509 4.22453 9.74795 4.22581C9.82081 4.2271 9.89033 4.25662 9.94186 4.30815C9.99339 4.35968 10.0229 4.42919 10.0242 4.50206C10.0255 4.57492 9.99843 4.64544 9.94875 4.69875Z' fill='%23121212'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 0.7em top 47%, 0 0;
}
