.button {
  @apply text-sm px-4 py-[0.565rem] font-light rounded-md disabled:opacity-25 disabled:pointer-events-none;
}

.default,
.default[type='button'],
.default[type='submit'],
.default[type='reset'] {
  @apply bg-primary text-white hover:bg-primary-dark;
}

.default.danger,
.default.danger[type='button'],
.default.danger[type='submit'],
.default.danger[type='reset'] {
  @apply bg-danger hover:bg-red-600;
}

.default.warning,
.default.warning[type='button'],
.default.warning[type='submit'],
.default.warning[type='reset'] {
  @apply bg-warning hover:bg-yellow-600;
}

.outline,
.outline[type='button'],
.outline[type='submit'],
.outline[type='reset'] {
  @apply bg-white border border-primary text-primary hover:text-white hover:bg-primary py-[0.5rem];
}

.outline.danger,
.outline.danger[type='button'],
.outline.danger[type='submit'],
.outline.danger[type='reset'] {
  @apply border-danger text-danger hover:bg-danger hover:text-white;
}

.outline.warning,
.outline.warning[type='button'],
.outline.warning[type='submit'],
.outline.warning[type='reset'] {
  @apply border-warning text-warning hover:bg-warning hover:text-white;
}

.default.small,
.default.small[type='button'],
.default.small[type='submit'],
.default.small[type='reset'] {
  @apply px-3 py-[0.4375rem];
}

.outline.small,
.outline.small[type='button'],
.outline.small[type='submit'],
.outline.small[type='reset'] {
  @apply px-3 py-1.5;
}
